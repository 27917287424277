import React, { Component } from 'react';
import { withSize } from 'react-sizeme';

import Layout from '../components/Layout';
import ContentFilter from '../components/ContentFilter';
import PageTitleBanner from '../components/PageTitleBanner';

import EditorialContentStyled from '../components/page-styles/editorialcontent-styles';
import SEO from '../components/SEO';

class EditorialContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            truckCount: null,
            paymentMethod: null,
            country: null,
        };
    }
    render() {
        const { size } = this.props;

        return (
            <Layout hideNav={false} needsSolidHeader={true} size={size}>
                <EditorialContentStyled>
                    <SEO title="Blog" />
                    <PageTitleBanner title="Fleet Insider By One9" />
                    <ContentFilter />
                </EditorialContentStyled>
            </Layout>
        );
    }
}

export default withSize()(EditorialContent);
