import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useGlobal from '../store';

const ContentFilterStyled = styled.div`
    width: 100%;
    margin-top: 25px;
    .hidden {
        display: none !important;
    }

    .bold {
        font-weight: 700;
    }

    select {
        height: 48px;
        width: calc(100% - 40px);
        margin-left: 20px;
        font: 0.875rem ${props => props.theme.fonts[0]};
        line-height: 1.5rem;
        color: #757575;
        padding: 0 12px 0 15px;
        margin: 0px 20px 25px 20px;
        border: 1px solid ${props => props.theme.colors.lightGray};
    }

    .open {
        border-bottom: none !important;
    }

    .arrow {
        border: 1px solid #757575;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin: auto 18px auto auto;
    }

    .up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
    }

    .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    option:selected {
        font-weight: 700;
    }

    .mobile-hidden {
        display: none !important;
    }

    label {
        color: #404041;
        font: 300 1rem ${props => props.theme.fonts[0]};
        line-height: 1.1875rem;
        margin-left: 16px;
    }

    .mobile-bold {
        font-weight: 600;
    }

    .filter-list-container,
    .date-filter-options {
        border-bottom: 1px solid ${props => props.theme.colors.lightGray};
        border-right: 1px solid ${props => props.theme.colors.lightGray};
        border-left: 1px solid ${props => props.theme.colors.lightGray};
    }

    .filter-container,
    .date-filter-option {
        display: flex;
        align-items: center;
        height: 60px;
        border: 1px solid #ececec;
        padding-left: 15px;
    }

    .date-filter-option:hover {
        background: ${props => props.theme.colors.lightGray};
    }

    input {
        width: 16px;
        height: 16px;
    }

    input[type='checkbox'] {
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        outline: 0;
        border: 1px solid #212721;
        width: 16px;
        height: 16px;
    }

    input[type='checkbox']:checked {
        background: ${props => props.theme.colors.primaryYellow};
        border: none;
    }

    input[type='checkbox']:after {
        content: '';
        position: relative;
        left: 40%;
        top: 20%;
        width: 15%;
        height: 40%;
        border: solid #fff;
        border-width: 0 1px 2px 0;
        transform: rotate(45deg);
        display: none;
    }

    input[type='checkbox']:checked:after {
        display: block;
    }

    select:disabled {
        opacity: 1;
    }

    .mobile-filters-container,
    .date-filter-container {
        display: flex;
        flex-direction: column;
        width: calc(100% - 40px);
        font: 0.875rem ${props => props.theme.fonts[0]};
        line-height: 1.5rem;
        color: #757575;
        margin: 0px 20px 25px 20px;
    }

    .mobile-filter,
    .date-filter {
        height: 46px;
        width: calc(100% - 2px);
        border: 1px solid ${props => props.theme.colors.lightGray};
        display: flex;
        align-items: center;
    }

    .mobile-dropdown-label {
        margin-left: 15px;
    }

    .date-filter-label {
        margin-left: 15px;
    }

    @media screen and (min-width: 768px) {
        display: flex;
        justify-content: flex-end;
        margin-top: 0;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        height: 72px;
        max-height: 72px;

        .date-filter {
            width: 163px;
            margin: 0px 56px 24px 15px;
        }

        .mobile-filter {
            width: 168px;
            height: 46px;
            margin: 0 0 24px auto;
        }

        .mobile-filters-container {
            z-index: 10;
            width: 168px;
            margin: 0;
        }

        .date-filter-container {
            z-index: 10;
            width: 168px;
            margin: 0 8% 0 0;
        }

        .filter-list-container {
            width: 168px;
            margin: -26px 0 0 0;
        }

        .date-list-container {
            width: 165px;
            margin: -26px 0 0 15px;
        }

        .filter-container,
        .date-filter-option {
            background: white;
        }
    }

    @media screen and (min-width: 1054px) {
        width: 100%;
        justify-content: space-between;
        margin-top: -26px;
        padding: 0 calc((100% - 1192px) / 2);
        height: 100px;
        max-height: 100px;
        display: flex;
        flex-direction: row;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        box-sizing: border-box;

        .filter-list-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: inherit;
            margin: 0 0 0 16px;
            border: 0;
        }

        .filter-container {
            border-bottom: none;
            padding-left: 0px;
            border: none;
            margin-right: 40px;
        }

        .mobile-hidden {
            display: flex !important;
        }

        input {
            width: 24px;
            min-width: 24px;
            height: 24px;
            min-height: 24px;
            margin-right: 16px;
        }

        .date-filter-container {
            width: 160px;
            display: flex;
            align-items: center;
            margin-right: 16px;
        }

        .date-filter {
            height: 50px;
            min-height: 50px;
            width: 160px;
            font-size: 1rem;
            margin-top: 23.75px;
            margin-right: 0;
            margin-left: 0;
        }

        .date-list-container {
            width: 162px;
            z-index: 10;
            margin-left: 0;
        }

        input[type='checkbox'] {
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: 0;
            border: 1px solid #212721;
            border-radius: 5px;
            width: 24px;
            height: 24px;
            /* margin: 0px 15px 0px 40px; */
        }

        input[type='checkbox']:checked {
            background: ${props => props.theme.colors.primaryYellow};
            border: none;
        }

        input[type='checkbox']:after {
            content: '';
            position: relative;
            left: 35%;
            top: 15%;
            width: 25%;
            height: 45%;
            border: solid #fff;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            display: none;
        }

        input[type='checkbox']:checked:after {
            display: block;
        }

        .mobile-filter {
            display: none;
        }

        label {
            letter-spacing: 0.145rem;
            line-height: 1.25rem;
            color: ${props => props.theme.colors.primaryGray};
            margin: 0;
            text-transform: uppercase;
            font: 0.875rem ${props => props.theme.fonts[1]};
        }

        .mobile-bold {
            font-weight: initial;
        }

        .mobile-filters-container {
            margin: 0;
            width: initial;
            display: flex;
            justify-content: center;
        }

        .filter-container,
        .date-filter-option {
            background: white;
        }
    }
`;

const ContentFilter = props => {
    const [checkboxLabels, setCheckboxLabels] = useState(props.checkboxLabels);
    const [checkboxKeys, setCheckboxKeys] = useState(props.checkboxKeys);
    const [country, setCountry] = useState(props.country);
    const [displayMobileFilters, setDisplayMobileFilters] = useState(
        props.displayMobileFilters
    );
    const [mobileDropdownLabel, setMobileDropdownLabel] = useState(
        props.mobileDropdownLabel
    );
    const [
        defaultMobileDropdownLabel,
        setDefaultMobileDropdownLabel,
    ] = useState(props.defaultMobileDropdownLabel);
    const [activeFilters, setActiveFilters] = useState(props.activeFilters);
    const [activeFilterLabels, setActiveFilterLabels] = useState(
        props.activeFilterLabels
    );
    const [dateFilterDefaultLabel, setDateFilterDefaultLabel] = useState(
        props.dateFilterDefaultLabel
    );
    const [dateFilterLabel, setDateFilterLabel] = useState(
        props.dateFilterLabel
    );
    const [dateFilterValue, setDateFilterValue] = useState(
        props.dateFilterValue
    );
    const [displayDateFilters, setDisplayDateFilters] = useState(
        props.displayDateFilters
    );
    const [firstRender, setFirstRender] = useState(true);
    const [globalState, globalActions] = useGlobal();

    useEffect(() => {
        if (firstRender) {
            setFirstRender(false);
        } else {
            var contentFilter = {
                activePage: 1,
                pageSize: globalState?.contentFilter?.pageSize ?? 9,
                activeDaysFilter:
                    dateFilterValue ??
                    globalState?.contentFilter?.activeDaysFilter ??
                    0,
                activeTypeFilters: activeFilters ?? null,
            };

            globalActions.services.getFilteredBlogsService(contentFilter);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters, dateFilterValue]);

    const filterClicked = (filterKey, filterLabel) => {
        // TODO: Use filter key to execute filtering logic
        var newFilters,
            newLabels = [];
        var newFilterLabel;

        if (activeFilters.indexOf(filterKey) > -1) {
            newFilters = activeFilters.filter(x => x !== filterKey);
            newLabels = activeFilterLabels.filter(x => x !== filterLabel);
        } else if (filterKey.indexOf(',') > -1) {
            var splitFilter = filterKey.split(',');
            newLabels = activeFilterLabels.concat(filterLabel);
            newFilters = activeFilters;
            for (let i = 0; i < splitFilter.length; i++) {
                if (activeFilters.indexOf(splitFilter[i]) > -1) {
                    newFilters = newFilters.filter(x => x !== splitFilter[i]);
                    newLabels = newLabels.filter(x => x !== filterLabel);
                } else {
                    newFilters = newFilters.concat(splitFilter[i]);
                    newFilters = newFilters.filter(x => x !== '*');
                    newLabels = newLabels.filter(x => x !== 'All');
                }
            }
        } else {
            if (filterKey === '*') {
                newFilters = [].concat(filterKey);
                newLabels = [].concat(filterLabel);
                if (globalState.contentFilter != null) {
                    globalState.contentFilter.activeTypeFilters = [];
                }
            } else {
                newFilters = activeFilters.concat(filterKey);
                newLabels = activeFilterLabels.concat(filterLabel);
                newFilters = newFilters.filter(x => x !== '*');
                newLabels = newLabels.filter(x => x !== 'All');
            }
        }

        if (newLabels.length > 0) {
            newFilterLabel = newLabels[0];
        } else {
            newFilterLabel = defaultMobileDropdownLabel;
        }

        // Set filter state to "all" if user has removed their last filter
        if (newFilters == null || newFilters.length === 0) {
            newFilters = ['*'];
            globalState.contentFilter.activeTypeFilters = [];
        }
        if (newLabels == null || newLabels === 0) {
            newLabels = ['All'];
        }
        setDisplayMobileFilters(false);
        if (newLabels.length > 1) {
            setMobileDropdownLabel('Filters: ' + newLabels.length);
        } else {
            setMobileDropdownLabel(newFilterLabel);
        }
        setActiveFilters(newFilters);
        setActiveFilterLabels(newLabels);
    };

    const dateRangeClick = (dateFilterLabel, newDateFilterValue) => {
        if (newDateFilterValue === dateFilterValue) {
            setDateFilterLabel(dateFilterDefaultLabel);
            setDateFilterValue('0');
            setDisplayDateFilters(false);
        } else {
            setDateFilterLabel(dateFilterLabel);
            setDateFilterValue(newDateFilterValue);
            setDisplayDateFilters(false);
        }
    };

    const openMobile = dropdown => {
        setDisplayMobileFilters(!displayMobileFilters);
    };

    const openDateFilters = () => {
        setDisplayDateFilters(!displayDateFilters);
    };

    if (globalState.contentFilter != null) {
        // If the filters are in their default state but the global state has an old filter profile, apply it
        if (
            globalState.contentFilter.activeTypeFilters != null &&
            globalState.contentFilter.activeTypeFilters.length !== 0 &&
            activeFilters.length === 1 &&
            activeFilters[0] === '*'
        ) {
            setActiveFilters(globalState.contentFilter.activeTypeFilters);

            // Find the label for the active filter taken from the global state and apply it to the mobile dropdown.
            var labelFound = false;
            for (
                var i = 0;
                i < globalState.contentFilter.activeTypeFilters.length;
                i++
            ) {
                for (var x = 0; x < props.checkboxKeys.length; x++) {
                    if (
                        props.checkboxKeys[x].includes(
                            globalState.contentFilter.activeTypeFilters[i]
                        )
                    ) {
                        setMobileDropdownLabel(props.checkboxLabels[x]);
                        labelFound = true;
                        break;
                    }
                }
                if (labelFound) {
                    break;
                }
            }
        }

        // If the date filters are in their default state, apply the global filter if available
        if (
            dateFilterValue === '0' &&
            globalState.contentFilter.activeDaysFilter !== '0'
        ) {
            setDateFilterValue(globalState.contentFilter.activeDaysFilter);
            setDateFilterLabel(
                props.dateFilterLabelMappings[
                    globalState.contentFilter.dateFilterValue
                ]
            );
        }
    }

    return (
        <ContentFilterStyled>
            <div className="mobile-filters-container">
                <div
                    className={
                        displayMobileFilters
                            ? 'mobile-filter open'
                            : 'mobile-filter'
                    }
                    name="mobile-type-filter"
                    onClick={() => openMobile()}
                >
                    <span
                        className={
                            mobileDropdownLabel !== defaultMobileDropdownLabel &&
                            !displayMobileFilters
                                ? 'mobile-dropdown-label bold'
                                : 'mobile-dropdown-label'
                        }
                    >
                        {displayMobileFilters
                            ? defaultMobileDropdownLabel
                            : mobileDropdownLabel}
                    </span>
                    <i
                        className={
                            displayMobileFilters ? 'arrow up' : 'arrow down'
                        }
                    ></i>
                </div>

                <div
                    className={
                        displayMobileFilters
                            ? 'filter-list-container'
                            : 'filter-list-container mobile-hidden'
                    }
                >
                    {checkboxLabels.map(
                        (checkboxLabel, checkboxIndex) => (
                            <div
                                className="filter-container"
                                key={checkboxIndex}
                            >
                                <input
                                    type="checkbox"
                                    value={checkboxKeys[checkboxIndex]}
                                    id={checkboxKeys[checkboxIndex]}
                                    checked={
                                        activeFilters.indexOf(
                                            checkboxKeys[checkboxIndex].split(
                                                ','
                                            )[0]
                                        ) > -1
                                            ? true
                                            : false
                                    }
                                    onClick={() =>
                                        filterClicked(
                                            checkboxKeys[checkboxIndex],
                                            checkboxLabel
                                        )
                                    }
                                />
                                <label
                                    htmlFor={checkboxKeys[checkboxIndex]}
                                    className={
                                        activeFilters.indexOf(
                                            checkboxKeys[checkboxIndex].split(
                                                ','
                                            )[0]
                                        ) > -1
                                            ? 'mobile-bold'
                                            : ''
                                    }
                                >
                                    {checkboxLabel}
                                </label>
                            </div>
                        ),
                        this
                    )}
                </div>
            </div>

            <div className="date-filter-container">
                <div
                    className="filter-"
                    className={
                        displayDateFilters ? 'date-filter open' : 'date-filter'
                    }
                    id="date"
                    name="date"
                    onClick={() => openDateFilters()}
                >
                    <span
                        className={
                            dateFilterLabel !== dateFilterDefaultLabel &&
                            !displayDateFilters
                                ? 'date-filter-label bold'
                                : 'date-filter-label'
                        }
                    >
                        {displayDateFilters
                            ? dateFilterDefaultLabel
                            : dateFilterLabel ??
                              props.dateFilterLabelMappings[dateFilterValue] ??
                              dateFilterDefaultLabel}
                    </span>
                    <i
                        className={
                            displayDateFilters ? 'arrow up' : 'arrow down'
                        }
                    ></i>
                </div>
                <div className="date-list-container">
                    <div
                        className={
                            displayDateFilters
                                ? 'date-filter-options'
                                : 'date-filter-options hidden'
                        }
                    >
                        <div
                            onClick={() => dateRangeClick('Last 7 Days', '7')}
                            className={
                                dateFilterValue === '7'
                                    ? 'date-filter-option bold'
                                    : 'date-filter-option'
                            }
                        >
                            {props.dateFilterLabelMappings['7']}
                        </div>
                        <div
                            onClick={() => dateRangeClick('Last 30 Days', '30')}
                            className={
                                dateFilterValue === '30'
                                    ? 'date-filter-option bold'
                                    : 'date-filter-option'
                            }
                        >
                            {props.dateFilterLabelMappings['30']}
                        </div>
                        <div
                            onClick={() => dateRangeClick('Last 90 Days', '90')}
                            className={
                                dateFilterValue === '90'
                                    ? 'date-filter-option bold'
                                    : 'date-filter-option'
                            }
                        >
                            {props.dateFilterLabelMappings['90']}
                        </div>
                        <div
                            onClick={() => dateRangeClick('Older', '-90')}
                            className={
                                dateFilterValue === '-90'
                                    ? 'date-filter-option bold'
                                    : 'date-filter-option'
                            }
                        >
                            {props.dateFilterLabelMappings['-90']}
                        </div>
                    </div>
                </div>
            </div>
        </ContentFilterStyled>
    );
};

ContentFilter.propTypes = {
    checkboxLabels: PropTypes.array,
    checkboxKeys: PropTypes.array,
    dateFilterLabelMappings: PropTypes.object,
    country: PropTypes.string,
    displayMobileFilters: PropTypes.bool,
    mobileDropdownLabel: PropTypes.string,
    defaultMobileDropdownLabel: PropTypes.string,
    activeFilters: PropTypes.array,
    activeFilterLabels: PropTypes.array,
    dateFilterDefaultLabel: PropTypes.string,
    dateFilterLabel: PropTypes.string,
    dateFilterValue: PropTypes.string,
    displayDateFilters: PropTypes.bool,
};

ContentFilter.defaultProps = {
    checkboxLabels: [
        'All',
        'Articles',
        'Videos',
        'Case Studies',
        'Infographic',
    ],
    checkboxKeys: [
        '*',
        'article-large-image,article-small-image',
        'video',
        'case-study',
        'infographic',
    ],
    dateFilterLabelMappings: {
        '7': 'Last 7 Days',
        '30': 'Last 30 Days',
        '90': 'Last 90 Days',
        '-90': 'Older',
    },
    country: null,
    displayMobileFilters: false,
    mobileDropdownLabel: 'Filter By',
    defaultMobileDropdownLabel: 'Filter By',
    activeFilters: ['*'],
    activeFilterLabels: ['All'],
    dateFilterDefaultLabel: 'Date Range',
    dateFilterLabel: 'Date Range',
    dateFilterValue: '0',
    displayDateFilters: false,
};

export default ContentFilter;
